import React, {useEffect, useState} from 'react';
import { Box } from "@mui/material";
import DataTableComponent from "../../dataTable/DataTableComponent";
import { getColumns } from "./monthlyConfig";
import { format } from 'date-fns';
import {useTranslation} from "react-i18next";

const YearlySaasBillingContainer = ({ saasBillings, onDataChange, filterDate }) => {
    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        total: 0,
    });

    useEffect(() => {
        const monthlyTotals = {
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
        };

        const transformedData = saasBillings.map((billing) => {
            const monthlyValues = {
                jan: 0,
                feb: 0,
                mar: 0,
                apr: 0,
                may: 0,
                jun: 0,
                jul: 0,
                aug: 0,
                sep: 0,
                oct: 0,
                nov: 0,
                dec: 0,
            };

            const currentYear = new Date(filterDate).getFullYear();
            const startDate = new Date(billing.beginDate);
            const endDate = new Date(billing.endDate);
            const startMonth = startDate.getMonth();
            const startYear = startDate.getFullYear();

            if (billing.period === 'Annual') {
                const renewalMonth = startMonth;
                const monthName = format(new Date(2000, renewalMonth), 'MMM').toLowerCase();
                const lastDayOfRenewalMonth = new Date(currentYear, renewalMonth + 1, 0);

                if (endDate >= lastDayOfRenewalMonth) {
                    monthlyValues[monthName] = billing.netValue;
                    monthlyTotals[monthName] += billing.netValue;
                }
            } else if (billing.period === 'Semester') {
                const firstPaymentMonth = startMonth;
                const secondPaymentMonth = (startMonth + 6) % 12;

                const firstMonthName = format(new Date(2000, firstPaymentMonth), 'MMM').toLowerCase();
                const secondMonthName = format(new Date(2000, secondPaymentMonth), 'MMM').toLowerCase();

                const lastDayOfFirstPaymentMonth = new Date(currentYear, firstPaymentMonth + 1, 0);
                const lastDayOfSecondPaymentMonth = new Date(currentYear, secondPaymentMonth + 1, 0);

                if (endDate >= lastDayOfFirstPaymentMonth) {
                    monthlyValues[firstMonthName] = billing.netValue;
                    monthlyTotals[firstMonthName] += billing.netValue;
                }
                if (endDate >= lastDayOfSecondPaymentMonth) {
                    monthlyValues[secondMonthName] = billing.netValue;
                    monthlyTotals[secondMonthName] += billing.netValue;
                }
            } else if (billing.period === 'Monthly') {
                for (let i = 0; i < 12; i++) {
                    const monthName = format(new Date(2000, i), 'MMM').toLowerCase();
                    const lastDayOfMonth = new Date(currentYear, i + 1, 0);
                    if ((currentYear > startYear || (currentYear === startYear && i >= startMonth)) && endDate >= lastDayOfMonth) {
                        monthlyValues[monthName] = billing.valueMonthly;
                        monthlyTotals[monthName] += billing.valueMonthly;
                    }
                }
            }

            return {
                id: billing.id,
                farmName: billing.farmName,
                group: billing.group,
                planName: billing.planName,
                period: billing.period,
                beginDate: billing.beginDate,
                endDate: billing.endDate,
                netValue: billing.netValue,
                monthlyValues: monthlyValues,
                total: Object.values(monthlyValues).reduce((sum, value) => sum + (value || 0), 0),
            };
        });

        const finalTotal = Object.values(monthlyTotals).reduce((sum, value) => sum + value, 0);
        setTotals({ ...monthlyTotals, total: finalTotal });
        setData(transformedData);

        const exportData = transformedData.map(row => ({
            ...row,
            jan: row.monthlyValues.jan || 0,
            feb: row.monthlyValues.feb || 0,
            mar: row.monthlyValues.mar || 0,
            apr: row.monthlyValues.apr || 0,
            may: row.monthlyValues.may || 0,
            jun: row.monthlyValues.jun || 0,
            jul: row.monthlyValues.jul || 0,
            aug: row.monthlyValues.aug || 0,
            sep: row.monthlyValues.sep || 0,
            oct: row.monthlyValues.oct || 0,
            nov: row.monthlyValues.nov || 0,
            dec: row.monthlyValues.dec || 0,
            total: row.total || 0,
        }));

        onDataChange([...exportData, {
            id: 'totals',
            farmName: t('generic.total'),
            group: '-',
            planName: '-',
            jan: totals.jan,
            feb: totals.feb,
            mar: totals.mar,
            apr: totals.apr,
            may: totals.may,
            jun: totals.jun,
            jul: totals.jul,
            aug: totals.aug,
            sep: totals.sep,
            oct: totals.oct,
            nov: totals.nov,
            dec: totals.dec,
            total: finalTotal,
        }]);

        // eslint-disable-next-line
    }, [saasBillings, onDataChange]);

    const columns = getColumns(t);

    const totalsRow = {
        id: 'totals',
        farmName: t('generic.total'),
        group: '-',
        planName: '-',
        monthlyValues: totals,
        total: totals.total,
    };


    return (
        <Box sx={{ margin: 'auto', padding: 2 }}>
            <DataTableComponent
                columns={columns}
                data={[...data, totalsRow]}
                pagination
            />
        </Box>
    );
};

export default YearlySaasBillingContainer;